.exclusion-cb tr td:last-child {
  padding-bottom: 2px;
  padding-top: 2px;

  div {
    float: right;
    padding-left: 10px;
  }
}

@media screen and (max-width: 43em) {
  .exclusion-cb tr td:last-child {
    padding-bottom: 1px;
    padding-top: 1px;
  }
}

@media screen and (max-width: 37em) {
  .exclusion-cb tr td:last-child {
    padding-bottom: 10px;
    padding-top: 10px;

    input {
      padding-bottom: 20px;
      position: relative;
    }
  }
}
