@media screen and (max-width: 43em) {
  .rcr-responsive-summary-table {
    font-size: 1rem;
  }
}

@media screen and (max-width: 37em) {
  .rcr-responsive-summary-table {
    thead {
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    tr {
      border: 1px solid #ddd;
    }

    tr:nth-last-child(1) {
      margin-top: 1em;
    }

    td {
      display: block;
      padding-left: .5em !important;
      padding-right: .5em !important;
      border-bottom: 0;
      text-align: right;
    }

    td::before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
    }
  }
}
