$top-color: #005ea5;
$border-color: #dee0e2;

.spinner-container {
  left: calc(50% - 30px);
  position: absolute;
  top: calc(50% - 30px);
  visibility: hidden;
  z-index: 10;
}

.spinner {
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  border: 12px solid $top-color;
  border-radius: 50%;
  border-top-color: $border-color;
  height: 60px;
  width: 60px;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
