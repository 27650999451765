// Media print for the review and confirmation pages

@media print {
  .govuk-footer__inline-list,

  .govuk-phase-banner {
    display: none;
  }

  .print-area *,
  #review * {
    margin-bottom: govuk-spacing(6);
  }

  .no-print-area {
    display: none !important;
  }

  #print-link {
    display: none;
  }

  .govuk-footer {
    display: none;
  }

  .govuk-header__content > .govuk-header__menu-button {
    display: none;
  }
}

// Hide print only areas on the screen
@media screen {
  .print-area, .print-area * {
    display: none;
  }
}
// Add a right margin for adjacent buttons
.govuk-button-adjacent {
  margin-right: 22px;
}

// Like phase banner
.licence-info {
  border-bottom: 1px solid #bfc1c3;
  padding-bottom: 10px;
  padding-top: 10px;
  text-align: right;
}

// hide button styles
.button-style-hidden {
  background: none;
  border: none;
  color: -webkit-link;
  font-size: 1.2rem;
  line-height: 1;
  padding: 0;
  cursor: pointer;
  text-decoration: underline;
}

// Global cookie message
#global-cookie-message {
  padding: 20px 0;
}

.summary-link-right {
  text-align: right;
}

// Elements to be unhidden by javascript if present
.hide-if-js-disabled {
  display:none;
}

// Hidden label to allow screen readers to read tabular links e.g. change/delete etc.
.link-reader {
  display: none;
}

.download-list {
  list-style-image: url('/public/images/icon-file-download.png');
}

.date-disabled {
  color: black !important;
  border: none !important;
  background-color: transparent !important;
}

.govuk-date-input--inline {
  display: inline-block;
  font-size: 0;
}

.rcr-default-date {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 20px;
  padding-top: 7px;
}

.rcr-default-date--single {
  @extend .rcr-default-date;
  padding-top: 28px;
}

@media screen and (max-width: 594px) {
  .rcr-default-date {
    position: relative;
  }

  .rcr-default-date--single {
    padding-top: 0;
    position: relative;
  }

  .govuk-date-input--inline {
    display: block;
    position: relative;
  }
}


